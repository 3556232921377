/* Container for the form */
.add-property-form {
  margin: 120px auto;
  max-width: 700px;
  padding: 40px;
  background-color: #ffffff;
  border-radius: 12px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  border: 1px solid #e0e0e0;
  font-family: 'Roboto', sans-serif;
  position: relative;
  overflow: hidden;
}

/* Form Heading */
.add-property-form h2 {
  text-align: center;
  margin-bottom: 30px;
  font-size: 24px;
  color: #333;
  font-weight: 600;
  position: relative;
  padding-bottom: 10px;
}

/* Decorative Underline for Heading */
.add-property-form h2::after {
  content: '';
  display: block;
  width: 60px;
  height: 3px;
  background-color: #0288d1;
  margin: 0 auto;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

/* Input Fields Styling */
.add-property-form input[type="text"],
.add-property-form input[type="number"],
.add-property-form input[type="file"],
.add-property-form textarea {
  display: block;
  margin: 16px 0;
  padding: 14px;
  width: 100%;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-sizing: border-box;
  transition: border-color 0.3s, box-shadow 0.3s;
  font-size: 16px;
  font-family: 'Roboto', sans-serif;
  background-color: #f9f9f9;
}

/* Focused Input Fields */
.add-property-form input[type="text"]:focus,
.add-property-form input[type="number"]:focus,
.add-property-form textarea:focus {
  border-color: #0288d1;
  box-shadow: 0 0 10px rgba(0, 136, 212, 0.3);
  outline: none;
  background-color: #ffffff;
}

/* File Input Styling */
.add-property-form input[type="file"] {
  padding: 0;
  cursor: pointer;
}

/* Submit Button Styling */
.add-property-form button {
  padding: 14px 28px;
  background-color: #0288d1;
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
  transition: background-color 0.3s, transform 0.3s, box-shadow 0.3s;
  width: 100%;
  margin-top: 20px;
  position: relative;
}

/* Button Hover Effects */
.add-property-form button:hover:not(:disabled) {
  background-color: #0277bd;
  transform: scale(1.03);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

/* Disabled Submit Button */
.add-property-form button:disabled {
  background-color: #b0bec5;
  cursor: not-allowed;
}

/* Error Message Styling */
.add-property-form .error {
  color: #e53935;
  font-size: 14px;
  margin: 5px 0;
  font-weight: 500;
  animation: fadeIn 0.5s ease-in;
}

/* Image Preview Styling */
.add-property-form .image-preview {
  margin: 16px 0;
  max-width: 100%;
  height: auto;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s;
}

/* Image Preview Hover Effect */
.add-property-form .image-preview:hover {
  transform: scale(1.05);
}

/* Response Message Styling */
.add-property-form .response-message {
  font-size: 16px;
  color: #388e3c;
  margin: 20px 0;
  padding: 14px;
  border-radius: 8px;
  background-color: #e8f5e9;
  border: 1px solid #c8e6c9;
  text-align: center;
  animation: fadeIn 0.5s ease-in;
}

/* Keyframes for Fade-In Animation */
@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}
