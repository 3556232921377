/* services.css */

.services-page {
  display: flex;
  margin-top: 80px;
  flex-direction: column;
  gap: 2rem; /* Space between sections */
  padding: 2rem;
  font-family: Arial, sans-serif; /* Improved font for readability */
  position: relative; /* For stacking context */
  opacity: 0; /* Initially hide the page */
  animation: fadeIn 1s forwards; /* Apply fade-in animation */
}

.services-page::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url('../assets/images/serviceback.jpg'); /* Update with your image path */
  background-attachment: fixed;
  background-size: cover;
  background-position: center;
  filter: brightness(20%) blur(1px); /* Adjust brightness and blur for duller effect */
  z-index: 0; /* Behind the content */
}

.service-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  position: relative; /* Needed for z-index stacking */
  z-index: 1; /* Ensure content is above the background */
  opacity: 0; /* Initially hide sections */
  transform: translateY(20px); /* Start with sections slightly below */
  animation: fadeInUp 0.5s forwards; /* Apply fade-in-up animation */
  animation-delay: calc(0.3s * var(--i)); /* Stagger animation for each section */
}

.service-section:nth-of-type(1) { --i: 1; }
.service-section:nth-of-type(2) { --i: 2; }
.service-section:nth-of-type(3) { --i: 3; }
/* Continue this pattern for additional sections */

.service-section:hover {
  transform: scale(1.02);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.service-text,
.service-3d {
  flex: 1;
  padding: 1rem;
}

.service-text h2 {
  color: wheat;
}

.service-text p {
  font-size: 18px;
  color: white;
  font-style: italic;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.service-text {
  order: 1; /* Default order for mobile */
  transition: color 0.3s ease;
}

.service-3d {
  order: 2; /* Default order for mobile */
}

@media (min-width: 768px) {
  .service-section {
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
  }

  .service-section:nth-of-type(even) .service-text {
    order: 2;
  }

  .service-section:nth-of-type(even) .service-3d {
    order: 1;
  }

  .service-text {
    font-size: 1.4rem; /* Larger font size for larger screens */
  }

  .service-3d iframe {
    height: 600px; /* Increase iframe height for larger screens */
  }
}

@media (max-width: 767px) {
  .service-3d iframe {
    height: 300px; /* Smaller height for smaller screens */
  }
}

@media (max-width: 480px) {
  .service-text {
    font-size: 1rem; /* Adjust font size for very small screens */
  }
}

/* Keyframes for animations */
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
