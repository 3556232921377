/* General Styles */
.home-page {
  font-family: Arial, sans-serif;
  overflow-x: hidden;
}

/* Hero Section */
.hero-container {
  height: 100vh;
  background-image: url('../images/caro4.avif');
  background-size: cover;
  background-position: center;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  text-align: center;
  position: relative;
  overflow: hidden;
}

.hero-container::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Adjust the opacity here (0.5) */
  z-index: 1;
}

.hero-container > * {
  position: relative;
  z-index: 2;
}


.hero-content h1 {
  font-size: 3rem;
  margin-bottom: 1rem;
  animation: fadeInDown 1s ease-out;
}

.hero-content p {
  font-size: 1.5rem;
  margin-bottom: 2rem;
  animation: fadeInUp 1.5s ease-out;
}

.hero-btn {
  padding: 0.8rem 2rem;
  background: #ff6200;
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 8px;
  transition: background-color 0.3s ease, transform 0.3s ease;
  animation: fadeInUp 2s ease-out;
}

.hero-btn:hover {
  background-color: #e55500;
  transform: scale(1.1);
}

@keyframes fadeInDown {
  0% { opacity: 0; transform: translateY(-20px); }
  100% { opacity: 1; transform: translateY(0); }
}

@keyframes fadeInUp {
  0% { opacity: 0; transform: translateY(20px); }
  100% { opacity: 1; transform: translateY(0); }
}
.core-values {
  padding: 50px 0;
  text-align: center;
  position: relative;
  background-image: url('../assets/images/coreback.jpeg'); /* Replace with your actual image path */
  background-size: cover;
  background-position: center;
  background-attachment: fixed; /* This keeps the background fixed while scrolling */
  color: white;
  z-index: 1;
}

.core-values::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6); /* Dark overlay to dull the background */
  z-index: -1;
}

.values-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
}

.value-card {
  background: rgba(255, 255, 255, 0.8); /* Slightly transparent background */
  border-radius: 10px;
  padding: 20px;
  width: 300px;
  text-align: left;
  position: relative;
  overflow: hidden;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
}

.value-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.2);
}

.image-container {
  width: 100%;
  height: 200px;
  overflow: hidden;
  border-radius: 10px 10px 0 0;
  margin-bottom: 15px;
}

.image-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease;
}

.value-card:hover .image-container img {
  transform: scale(1.1);
}

.value-card h3 {
  margin-top: 0;
  font-size: 1.5em;
  color: #03016d;
}

.value-card p {
  color: #555;
}


/* About Us Section */
.about-us {
  padding: 50px 20px;
  background-color: #fff;
  text-align: center;
}

.about-us h2 {
  color: #03016d;
  font-size: 2.5rem;
  margin-bottom: 20px;
}

.about-us p {
  font-size: 1.2rem;
  margin-bottom: 40px;
  line-height: 1.6;
}

.stats {
  display: flex;
  justify-content: space-around;
  text-align: center;
  padding: 50px 0;
  background-color: #f7f7f7; /* Example background */
}

.stat {
  flex: 1;
  margin: 0 20px;
}

.stat h3 {
  font-size:3em;
  color: #03016d;
  margin: 0;
  transition: transform 0.3s ease;
}

.stat p {
  font-size: 1.2em;
  color: #555;
}

.stat:hover h3 {
  transform: scale(1.1); /* Slight zoom effect on hover */
}

/* Services Section */
.services {
  padding: 50px 20px;
  background-color: #f4f4f4;
  text-align: center;
}

.services h2 {
  font-size: 2.5rem;
  margin-bottom: 20px;
}

.services p {
  font-size: 1.2rem;
  margin-bottom: 40px;
}

.services-container {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

.service-card {
  background: white;
  padding: 20px;
  margin: 10px;
  border-radius: 8px;
  width: 250px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  animation: fadeIn 2s ease-out;
}

.service-card:hover {
  transform: scale(1.05);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.service-card h3 {
  font-size: 1.5rem;
  margin-bottom: 10px;
}

.service-card p {
  font-size: 1rem;
}

/* Projects Section */
.projects {
  padding: 50px 20px;
  background-color: #fff;
  text-align: center;
}

.projects h2 {
  font-size: 2.5rem;
  margin-bottom: 20px;
}

.projects p {
  font-size: 1.2rem;
  margin-bottom: 40px;
}

.projects-gallery {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

.project-card {
  background: white;
  border-radius: 8px;
  margin: 10px;
  width: 300px;
  overflow: hidden;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  animation: fadeIn 2s ease-out;
}

.project-card img {
  width: 100%;
  height: auto;
}

.project-card:hover {
  transform: scale(1.05);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.project-info {
  padding: 15px;
}

.project-info h3 {
  font-size: 1.5rem;
  margin-bottom: 10px;
}

.project-info p {
  font-size: 1rem;
}

/* Client Collaboration Section */
/* ClientCollaboration.css */
.client-collaboration {
  padding: 60px 20px;
  background-color: #f9f9f9;
  text-align: center;
}

.client-collaboration h2 {
  font-size: 2.5em;
  color: #03016d;
  margin-bottom: 20px;
  animation: fadeIn 1s ease-in-out;
}

.client-collaboration p {
  font-size: 1.2em;
  color: #555;
  margin-bottom: 40px;
  animation: fadeIn 2s ease-in-out;
}

.collaboration-flow {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  gap: 20px;
  animation: fadeIn 3s ease-in-out;
}

.flow-step {
  background: #fff;
  border-radius: 10px;
  padding: 20px;
  width: 220px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  position: relative;
}

.flow-step:hover {
  transform: translateY(-10px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.step-icon {
  font-size: 2em;
  font-weight: bold;
  color: #fff;
  background-color: #007bff; /* Primary color for icons */
  border-radius: 50%;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
  position: absolute;
  top: -25px;
  left: 50%;
  transform: translateX(-50%);
}

.flow-step h3 {
  font-size: 1.5em;
  margin: 10px 0;
  color: #03016d;
}

.flow-step p {
  font-size: 1em;
  color: #666;
}

/* Animation Keyframes */
@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}


/* Contact Us Section */
.contact-us {
  padding: 50px 20px;
  background-color: #fff;
  text-align: center;
  position: relative;
}

.contact-us.show {
  display: block;
}

.contact-us h2 {
  font-size: 2.5rem;
  margin-bottom: 20px;
}

.contact-us p {
  font-size: 1.2rem;
  margin-bottom: 40px;
}

.contact-form {
  max-width: 600px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
}

.contact-form input,
.contact-form textarea {
  margin-bottom: 20px;
  padding: 15px;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 8px;
}

.contact-form button {
  padding: 15px;
  background-color: #ff6200;
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 8px;
  transition: background-color 0.3s ease;
}

.contact-form button:hover {
  background-color: #e55500;
}

.contact-toggle {
  background: none;
  border: none;
  color: #ff6200;
  font-size: 1rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-top: 20px;
  transition: color 0.3s ease;
}

.contact-toggle:hover {
  color: #e55500;
}
/* Testimonials.css */
/* TestimonialsSlider.css */
.testimonials-slider  {
  padding: 60px 20px;
  background-color: #f0f0f0;
  text-align: center;
  position: relative;
  background-image: url('../assets/images/clientback.jpg'); /* Path to your background image */
  background-size: cover;
  background-position: center;
  z-index: 1;
  
 
}
.testimonials-slider1 h1{
  color: #03016d;
  
  font-weight: bolder;
  text-align: center;
}
.testimonials-slider::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3); /* Duller overlay */
  
}

.slider-container {
  position: relative;
  max-width: 800px;
  margin: 0 auto;
  overflow: hidden;
  border-radius: 10px;
  background: #fff; /* Background for the slider content */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 2; /* Ensure slider content is above the overlay */
}

.testimonial-slide {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
}

.avatar {
  border-radius: 50%;
  overflow: hidden;
  width: 100px;
  height: 100px;
  border: 5px solid #007bff; /* Primary color */
  background: #fff;
  margin-right: 20px;
}

.avatar img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.testimonial-content {
  text-align: left;
}

.testimonial-text {
  font-size: 1.2em;
  color: #555;
  margin-bottom: 20px;
  font-style: italic;
}

.testimonial-name {
  font-size: 1.5em;
  color: #333;
  margin: 0;
}

.testimonial-position {
  font-size: 1.1em;
  color: #777;
}

.arrow {
  background: #007bff;
  color: white;
  border: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  font-size: 1.5em;
  cursor: pointer;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 10;
  transition: background 0.3s ease;
}

.left-arrow {
  left: 10px;
}

.right-arrow {
  right: 10px;
}

.arrow:hover {
  background: #0056b3;
}

.dots-container {
  margin-top: 20px;
}

.dot {
  height: 10px;
  width: 10px;
  margin: 0 5px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
  transition: background-color 0.3s ease;
  cursor: pointer;
}

.dot.active {
  background-color: #007bff;
}

/* Animation Keyframes */
@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Animations */
@keyframes fadeIn {
  from {
      opacity: 0;
  }
  to {
      opacity: 1;
  }
}

@keyframes slideIn {
  from {
      transform: translateY(20px);
      opacity: 0;
  }
  to {
      transform: translateY(0);
      opacity: 1;
  }
}

@media (max-width: 767px){
  .stat h3 {
    font-size:1.5em;
    
  }
  
}
@media (min-width:1024px) and (max-width:1439px){
  .value-card {
    width: 200px;
    height: 350px;
  }
}

/* Keyframe animations */
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes slideIn {
  from {
    transform: translateX(-100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes fadeUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

