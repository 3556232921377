.project-list-container {
    padding: 20px;
  }
  
  .project-list h2 {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .project-list table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .project-list th, .project-list td {
    padding: 12px;
    text-align: left;
    border-bottom: 1px solid #ddd;
  }
  
  .project-list img {
    max-width: 100px;
    height: auto;
    border-radius: 4px;
  }
  
  .delete-button {
    background-color: #e74c3c;
    color: white;
    border: none;
    padding: 8px 16px;
    cursor: pointer;
    border-radius: 4px;
    transition: background-color 0.3s;
  }
  
  .delete-button:hover {
    background-color: #c0392b;
  }
  
  .animated-row {
    transition: background-color 0.3s;
  }
  
  .animated-row:hover {
    background-color: #f9f9f9;
  }
  