/* PropertyList.css */

.property-list-container {
  margin: 40px auto;
  padding: 20px;
  max-width: 80%;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.property-list-container h2 {
  text-align: center;
  color: #333;
  font-family: 'Arial', sans-serif;
  margin-bottom: 20px;
  font-weight: 600;
}

.property-list {
  width: 100%;
  overflow-x: auto;
}

.property-list table {
  width: 100%;
  border-collapse: collapse;
}

.property-list th, 
.property-list td {
  border: 1px solid #ddd;
  padding: 12px 15px;
  text-align: left;
  font-family: 'Arial', sans-serif;
}

.property-list th {
  background-color: #0288d1;
  color: white;
  font-weight: bold;
}

.property-list td {
  background-color: white;
}

.property-list img {
  border-radius: 5px;
  width: 80px;
  height: auto;
  object-fit: cover;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.delete-button {
  padding: 8px 16px;
  background-color: #ff6b6b;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-family: 'Arial', sans-serif;
  transition: background-color 0.3s ease;
}

.delete-button:hover {
  background-color: #ff4c4c;
}

.animated-row {
  animation: fadeIn 0.8s ease-in-out;
}

@keyframes fadeIn {
  from { opacity: 0; transform: translateY(20px); }
  to { opacity: 1; transform: translateY(0); }
}
