.careers-wrapper {
    margin-top: 100px;
  }
  
  /* Intro Section */
  .intro-section {
    background: #f7f7f7; 
   
    padding: 2rem;
    border-radius: 8px;
    margin-bottom: 2rem;
    text-align: center;
    animation: fadeInDown 1s ease-in-out;
  }
  
  .intro-image {
    width: 93vw;
    height: 40vh;
    border-radius: 8px;
    margin-bottom: 1rem;
    object-fit: cover;
    transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  }
  
  .intro-image:hover {
    transform: scale(1.05);
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  }
  
  .title {
    color: #03016d;
    font-size: 2rem;
    margin-bottom: 1rem;
    animation: fadeInLeft 1s ease-in-out;
  }
  
  .paragraph {
    font-size: 1rem;
    margin-bottom: 1rem;
    animation: fadeInRight 1s ease-in-out;
  }
  
  /* Join Our Team Section */
  .join-section {
    text-align: center;
    margin-bottom: 2rem;
    animation: fadeInUp 1s ease-in-out;
  }
  
  .join-section h2 {
    color: #03016d;
  }
  
  /* Job Listings */
  .job-list {
    display: flex;
    
    flex-direction: column;
    gap: 1rem;
  }
  
  .job-item {
    display: flex;
    
    flex-direction: column;
    
    align-items: center;
    padding: 1rem;
    border: 1px solid #ddd;
    border-radius: 8px;
    background: #fff;
    cursor: pointer;
    overflow: hidden;
    transition: transform 0.3s, box-shadow 0.3s;
    animation: fadeIn 0.5s ease-in-out;
  }
  
  .job-item:hover {
    transform: scale(1.03);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
  
  .job-details {
    text-align: center;
  }
  
  .job-title {
    color: #03016d;
    font-size: 1.2rem;
    margin: 0;
  }
  
  .job-description {
    margin: 0.5rem 0;
  }
  
  /* Apply Button */
  .apply-button {
    display: inline-block;
    padding: 0.5rem 1rem;
    color: #fff;
    background-color: #007bff;
    border: none;
    border-radius: 5px;
    text-decoration: none;
    font-weight: bold;
    transition: background 0.3s, transform 0.3s;
  }
  
  .apply-button:hover {
    background-color: #0056b3;
    transform: scale(1.05);
  }
  
  /* Submit Section */
  .submit-section {
    text-align: center;
    margin-top: 2rem;
    animation: fadeInUp 1s ease-in-out;
  }
  
  .submit-section h2 {
    margin-bottom: 1rem;
  }
  
  .submit-section p {
    font-size: 1rem;
  }
  
  /* Animations */
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  
  @keyframes fadeInLeft {
    from {
      opacity: 0;
      transform: translateX(-20px);
    }
    to {
      opacity: 1;
      transform: translateX(0);
    }
  }
  
  @keyframes fadeInRight {
    from {
      opacity: 0;
      transform: translateX(20px);
    }
    to {
      opacity: 1;
      transform: translateX(0);
    }
  }
  
  @keyframes fadeInUp {
    from {
      opacity: 0;
      transform: translateY(20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  @keyframes fadeInDown {
    from {
      opacity: 0;
      transform: translateY(-20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  /* Responsive Styles */
  @media (max-width: 768px) {
    .intro-image {
      width: 100%;
      height: auto;
    }
    .job-list {
      width: 90vw;
      flex-direction: column;
      gap: 1rem;
    }
    .job-item {
      width: 100%;
    }
    
  }
  