/* src/ContactUs.css */
.contactUs {
  display: flex;
  flex-direction: column; /* Stack items vertically */
  align-items: center;
  margin-top: 80px;
  background-image: url('../assets/images/contact_us.jpg');
  background-size: cover;
  background-position: center;
  padding: 20px;
  background-color: rgba(21, 21, 21, 0.5); /* Duller background */
  background-blend-mode: lighten; /* Apply blend mode to dull the image */
  animation: fadeIn 1s ease-in-out; /* Fade-in effect for the whole section */
}

.contactContainer {
  display: flex;
  flex-direction: column;
  width: 90%;
  max-width: 1200px;
  background: rgba(255, 255, 255, 0.8);
  padding: 20px;
  border-radius: 8px;
  margin-bottom: 20px;
  transition: transform 0.3s ease, box-shadow 0.3s ease; /* Animation for transform and shadow */
}

@media (min-width: 768px) {
  .contactContainer {
    flex-direction: row;
    justify-content: space-between; /* Space between items */
  }
}

.contactContainer:hover {
  transform: scale(1.05); /* Scale effect on hover */
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2); /* Add shadow on hover */
}

.contactInfo {
  flex: 1; /* Allow flex-grow to distribute space */
  margin-right: 20px; /* Space between the contact info and form */
  animation: fadeInLeft 1s ease-in-out; /* Slide-in from left */
}

.contactInfo h2 {
  margin-bottom: 20px;
  transition: color 0.3s ease; /* Animation */
}

.contactInfo h2:hover {
  color: #444444; /* Color change on hover */
}

.contactItem {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  transition: transform 0.3s ease; /* Animation */
}

.contactItem:hover {
  transform: translateX(10px); /* Slide effect on hover */
}

.contactItem .icon {
  margin-right: 10px;
  font-size: 24px;
  transition: color 0.3s ease; /* Animation */
}

.contactItem .icon:hover {
  color: #444444; /* Color change on hover */
}

.contactForm {
  flex: 1; /* Allow flex-grow to distribute space */
  width: 100%;
  max-width: 600px;
  transition: box-shadow 0.3s ease; /* Animation */
  animation: fadeInRight 1s ease-in-out; /* Slide-in from right */
}

.contactForm:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Shadow effect on hover */
}

.contactForm h2 {
  margin-bottom: 20px;
  transition: color 0.3s ease; /* Animation */
}

.contactForm h2:hover {
  color: #444444; /* Color change on hover */
}

.formGroup {
  margin-bottom: 15px;
}

.formGroup label {
  display: block;
  margin-bottom: 5px;
}

.formGroup input,
.formGroup textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  transition: border-color 0.3s ease, box-shadow 0.3s ease; /* Animation */
}

.formGroup input:hover,
.formGroup textarea:hover {
  border-color: #444444; /* Border color change on hover */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Add shadow on hover */
}

.formGroup .error {
  border-color: red;
}

.errorMessage {
  color: red;
  font-size: 12px;
  margin-top: 5px;
}

.successMessage {
  color: green;
  font-size: 14px;
  margin-top: 10px;
}

.btnPrimary {
  background-color: #5a5a5a;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease; /* Animation */
}

.btnPrimary:hover {
  background-color: #444444; /* Background color change on hover */
  transform: translateY(-2px); /* Slight lift effect on hover */
}

.mapContainer {
  width: 100%;
  height: 450px; /* Adjust height as needed */
  animation: fadeInUp 1s ease-in-out; /* Fade-in and slide-up effect */
}

.map {
  width: 100%;
  height: 100%;
  border: none;
}

/* Animations */
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeInLeft {
  from {
    opacity: 0;
    transform: translateX(-20px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes fadeInRight {
  from {
    opacity: 0;
    transform: translateX(20px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
