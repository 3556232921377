/* General Styles */
.projects-page {
    position: relative;
    padding: 20px;
    margin-top: 80px;
    background-image: url('../assets/images/serviceback.jpg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
}

/* Add a pseudo-element for the dull background effect */
.projects-page::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: -1;
    filter: brightness(50%) blur(4px);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

/* Heading */
.projects-page h1 {
    color: black;
    margin-bottom: 30px;
    font-weight: bolder;
    position: relative;
    z-index: 1;
}

/* Projects Grid */
.projects-grid {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
}

/* Project Card */
.project-card {
    width: 300px;
    height: 60vh;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 12px;
    background-color: rgb(255, 255, 255);
    cursor: pointer;
    text-align: center;
    transition: transform 0.3s ease, box-shadow 0.3s ease, background-color 0.3s ease;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.project-card:hover {
    transform: scale(1.05);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    background-color: rgba(255, 255, 255, 1);
}

.project-card img {
    width: 100%;
    height: 60%;
    object-fit: cover;
    border-radius: 8px;
    transition: transform 0.3s ease;
}

.project-card img:hover {
    transform: scale(1.1);
}

.project-card h2 {
    margin-top: 15px;
    color: #333;
    font-size: 1.75em;
}

.project-card p {
    color: #666;
    font-size: 1.1em;
}

/* Modal Styles */
.modal-backdrop {
    position: absolute; /* Changed to fixed to keep the backdrop fixed on the screen */
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    animation: fadeIn 0.5s ease;
}

.modal-content {
    background-color: white;
    padding: 20px;
    border-radius: 12px;
    width: 600px;
    text-align: center;
    align-items: center;
    position: relative;
    animation: slideIn 0.5s ease;
    max-height: 80vh;
    overflow-y: auto;
    margin-top: 20px;
}

.modal-content img {
    width: 100%;
    height: 50vh;
    object-fit: cover;
    margin-bottom: 15px;
    border-radius: 12px;
}

button {
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.3s ease;
}

button:hover {
    background-color: #0056b3;
    transform: scale(1.1);
}

/* Animations */
@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes slideIn {
    from {
        transform: translateY(20px);
        opacity: 0;
    }
    to {
        transform: translateY(0);
        opacity: 1;
    }
}

/* Media Query to Hide Modal on Small Screens */
@media (max-width: 768px) {
    .modal-backdrop {
        display: none; /* Hide the backdrop on small screens */
    }
    .project-card {
        width: 300px;
        height: 70vh;
        padding: 20px;
        border: 1px solid #ddd;
        border-radius: 12px;
        background-color: rgb(255, 255, 255);
        cursor: pointer;
        text-align: center;
        transition: transform 0.3s ease, box-shadow 0.3s ease, background-color 0.3s ease;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    }
    
}
