.portfolio-card {
  background-color: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
 
  display: flex;
  
  flex-direction: column;
  transition: transform 0.3s ease-in-out;
}

.portfolio-card:hover {
  transform: translateY(-10px);
}

.portfolio-card-image {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.portfolio-card-image img {
  width: 100%;
  height: 300px;
  object-fit: cover;
  transition: opacity 0.3s ease-in-out;
}

.portfolio-card:hover .portfolio-card-image img {
  opacity: 0.85;
}

.portfolio-card-info {
  padding: 20px;
  text-align: center;
  flex: none;
}

.portfolio-card-title {
  font-size: 1.5em;
  font-weight: bold;
  color: #333;
  margin-bottom: 10px;
}

.portfolio-card-price,
.portfolio-card-location,
.portfolio-card-sqft {
  font-size: 1em;
  color: #666;
  margin-bottom: 5px;
}

.portfolio-card-hover {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.portfolio-card:hover .portfolio-card-hover {
  opacity: 1;
}

.portfolio-card-hover p {
  font-size: 1.2em;
  font-weight: bold;
}
.contact-us-btn {
  display: inline-block;
  padding: 12px 24px;
  background-color: #007bff; /* Primary color */
  color: #fff;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  text-decoration: none;
  border-radius: 5px;
  transition: background-color 0.3s ease, transform 0.3s ease;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.contact-us-btn:hover {
  background-color: #0056b3; /* Darker shade on hover */
  transform: translateY(-2px); /* Slight lift on hover */
}

.contact-us-btn:active {
  background-color: #004494; /* Even darker shade on click */
  transform: translateY(0); /* Reset lift on click */
}

@media (max-width: 768px) {
  .contact-us-btn {
    font-size: 14px;
    padding: 10px 20px;
  }
}
