.portfolio-page {
  padding: 40px;
  margin-top: 80px;
  background-color: #e9ecef;
  min-height: 100vh;
}

.portfolio-page h1 {
  font-size: 2.5em;
  text-align: center;
  color: #333;
  margin-bottom: 30px;
  font-weight: bold;
}

.portfolio-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 20px;
}

@media (max-width: 768px) {
  .portfolio-grid {
    grid-template-columns: 1fr;
  }
}
