/* General Styles */
.header {
  background-color: #003366;
  color: white;
  padding: 1em 0;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 1000;
  transition: background-color 0.3s ease;
}

.header:hover {
  background-color: #002244; /* Darker shade on hover */
}

.header-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
 
  margin: 0 auto;
  padding: 0 1em;
  height: 5vh;
  
}   

.logo-container {
  display: flex;
  align-items: center;
}

.logo-image {
  height: 50px; /* Adjust size based on your design */
  border-radius: 50%; /* Ensuring the image is circular */
  margin-right: 10px;
  margin-left: -100px;
}

.logo-text {
  display: flex;
  flex-direction: column;
}

.company-name {
  font-size: 1.4em;
  font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
  font-weight: bold;
  transition: color 0.3s ease;
}

.company-name:hover {
  color: #ff6600; /* Change logo color on hover */
}

.tagline {
  font-size: 1.0em;
  font-style: italic;
  color: #ffcc00; /* Highlighting the tagline */
}

.nav ul {
  list-style-type: none;
  
  font-weight: bold;
  padding: 0;
  margin: 0;
  display: flex;
}

.nav ul li {
  margin: 0 1em;
}

.nav ul li a {
  color: white;
  text-decoration: none;
  transition: color 0.3s ease, transform 0.3s ease;
}

.nav ul li a:hover {
  color: #ff6600;
  transform: scale(1.1);
}

/* Mobile Menu Icon */
.menu-icon {
  display: none;
  flex-direction: column;
  cursor: pointer;
  transition: transform 0.3s ease;
}

.menu-icon .bar {
  width: 25px;
  height: 3px;
  background-color: white;
  margin: 4px 0;
  transition: all 0.3s ease;
}

.menu-icon .bar.open:nth-child(1) {
  transform: rotate(45deg) translate(5px, 5px);
}

.menu-icon .bar.open:nth-child(2) {
  opacity: 0;
}

.menu-icon .bar.open:nth-child(3) {
  transform: rotate(-45deg) translate(5px, -5px);
}
.header-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
}


.video-call-btn {
  
  background-color: #07e03ad6;
  margin-top: -5px;
  font-weight: bold;
  font-style: italic;
  color: black;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 1em;
  transition: background-color 0.3s ease-in-out;
}

.video-call-btn:hover {
  background-color: #1fcf00;
}


/* Responsive Styles */
@media (max-width: 768px) {
  .header {
    padding: 0.5em 0;
  }
  .company-name {
    font-size: 1.2em;
    
  }
  .tagline {
    font-size: 0.8em;
   
  }
  .logo-image {
    margin-left: 0; /* Remove the negative margin for mobile */
  }

  .nav {
    position: absolute;
    top: 60px;
    left: -100%;
    width: 100%;
    height: calc(100vh - 60px);
    background-color: #003366;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transition: left 0.3s ease;
    overflow: hidden;
  }

  .nav.open {
    left: 0;
  }

  .nav ul {
    flex-direction: column;
    align-items: center;
  }

  .nav ul li {
    margin: 1em 0;
    transition: transform 0.3s ease;
  }

  .nav ul li:hover {
    transform: scale(1.05);
  }

  .menu-icon {
    display: flex;
  }
  .nav ul {
    flex-direction: column;
    display: none;
  }

  .nav.open ul {
    display: flex;
  }

  .menu-icon {
    display: flex;
  }

  .contact-buttons {
    display: none;
  }
}


@media (min-width: 1024px) and (max-width:1439px){
  .logo-image {
    height: 40px; /* Adjust size based on your design */
    border-radius: 50%; /* Ensuring the image is circular */
    margin-right: 10px;
    margin-left: 0px;
  }
  

  .nav ul {
    
    font-size: 14px;
    font-weight: bold;
    padding: 0;
    margin: 0;
    display: flex;
  }
  .tagline {
    font-size: 0.7em;
    font-style: italic;
    color: #ffcc00; /* Highlighting the tagline */
  }
  .company-name {
    font-size: 1.2em;
    font-weight: bold;
    transition: color 0.3s ease;
  }
}


