/* About Us Page Specific Styles */
.about-us-page {
  padding: 20px;
  margin-top: 80px;
  background-image: url('../assets/images/aboutus.jpg');
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  position: relative;
  color: white;
  font-family: Arial, sans-serif; /* Improved font for readability */
}

/* Duller Background Overlay */
.about-us-page::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url('../assets/images/aboutus.jpg');
  background-size: cover;
  background-position: center;
  filter: brightness(20%) blur(2px); /* Adjust brightness and blur for duller effect */
  z-index: 0; /* Behind the content */
}

/* To ensure text and images are above the overlay */
.about-us-page .section {
  display: flex;
  justify-content: space-between;
  margin-bottom: 40px;
  align-items: stretch; /* Ensure items stretch to fill the container */
  flex-wrap: wrap;
  position: relative;
  z-index: 2;
  animation: fadeInUp 1s ease-in-out; /* Fade-in and slide-up effect */
}

/* Cards for Text */
.about-us-page .text-left, 
.about-us-page .text-right {
  flex: 1;
  padding: 20px;
  background-color: transparent; /* Semi-transparent white background for text cards */
  border-radius: 10px;
  margin: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

/* Hover Effect for Text Cards */
.about-us-page .text-left:hover, 
.about-us-page .text-right:hover {
  transform: scale(1.02);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
}

/* Image Styling */
.about-us-page .image-left,
.about-us-page .image-right {
  flex: 1;
  margin: 10px;
  position: relative;
  overflow: hidden;
  border-radius: 10px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

/* Hover Effect for Images */
.about-us-page .image-left:hover,
.about-us-page .image-right:hover img {
  transform: scale(1.05); /* Slight zoom effect */
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.3); /* Add shadow effect */
}

.about-us-page img {
  width: 100%;
  height: 100%; /* Ensure image covers the card */
  object-fit: cover; /* Maintain aspect ratio and cover card area */
}

/* Headings */
.about-us-page h2 {
  font-size: 2rem; /* Responsive font size */
  margin-bottom: 15px;
  color: wheat; /* Highlighted color */
}

/* Paragraph Styling */
.about-us-page p, 
.about-us-page ul li {
  font-size: 1rem; /* Responsive font size */
  line-height: 1.6;
  margin-bottom: 10px;
  color: white; /* Ensure text is readable against the dull background */
}

/* Interactive List */
.about-us-page ul {
  list-style-type: none;
  padding: 0;
}

.about-us-page ul li {
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  transition: transform 0.3s ease;
  cursor: pointer;
  animation: fadeInUp 1s ease-in-out; /* Fade-in and slide-up effect */
}

.about-us-page ul li:hover {
  transform: scale(1.05);
}

.about-us-page ul li strong {
  margin-left: 10px;
  font-weight: bold;
}

.about-us-page ul li .icon {
  color: #03016d; /* Highlighted color for icons */
  font-size: 1.5rem; /* Responsive icon size */
  margin-right: 10px;
  transition: color 0.3s ease;
}

.about-us-page ul li:hover .icon {
  color: #ff5733; /* Hover color for icons */
}

/* Animations */
@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Responsive Styles */
@media (max-width: 1200px) {
  .about-us-page h2 {
    font-size: 1.8rem; /* Slightly smaller heading font size */
  }

  .about-us-page p, 
  .about-us-page ul li {
    font-size: 1.1rem; /* Slightly larger font size for better readability */
  }
}

@media (max-width: 992px) {
  .about-us-page .section {
    flex-direction: column;
    text-align: center;
  }

  .about-us-page .text-left, 
  .about-us-page .text-right {
    order: 2; /* Ensure text appears after image */
    margin: 10px 0;
  }

  .about-us-page .image-left,
  .about-us-page .image-right {
    order: 1; /* Ensure image appears first */
    margin: 10px 0;
  }
}

@media (max-width: 768px) {
  .about-us-page h2 {
    font-size: 1.5rem; /* Smaller heading font size for mobile */
  }

  .about-us-page p, 
  .about-us-page ul li {
    font-size: 1rem; /* Adjust font size for mobile */
  }
}

@media (max-width: 480px) {
  .about-us-page h2 {
    font-size: 1.2rem; /* Smallest heading font size for very small screens */
  }

  .about-us-page p, 
  .about-us-page ul li {
    font-size: 0.9rem; /* Smallest font size for very small screens */
  }
}
