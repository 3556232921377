/* Carousel Container */
.custom-carousel {
  position: relative;
  margin-top: 80px;
  width: 100vw;
  overflow: hidden;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  animation: fadeIn 1s ease-in-out; /* Fade-in animation for the carousel */
}

/* Carousel Inner Wrapper */
.custom-carousel__inner {
  display: flex;
  transition: transform 0.5s ease-in-out;
}

/* Individual Carousel Items */
.custom-carousel__item {
  min-width: 100%;
  user-select: none;
  position: relative;
  filter: brightness(0.8); /* Slightly dull the images */
  transition: filter 0.3s ease, transform 0.3s ease; /* Transition for hover effects */
}

.custom-carousel__item img {
  width: 100%;
  height: auto;
  display: block;
  filter: brightness(0.9); /* Slightly dull the images */
}

/* Hover effect to brighten and zoom the image */
.custom-carousel__item:hover {
  filter: brightness(1);
  transform: scale(1.02);
}

/* Logo Overlay */
.custom-carousel__logo-overlay {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0.8); /* Slightly lighter black background */
  padding: 20px;
  border-radius: 8px;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: logoPulse 3s infinite;
}

.custom-carousel__logo {
  width: 20vw; /* Adjust size as needed */
  height: auto;
  animation: logoFadeIn 1s ease-in-out; /* Logo fade-in animation */
}

/* Control Buttons */
.custom-carousel__control {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(0, 0, 0, 0.3);
  border: none;
  color: #fff;
  font-size: 30px;
  padding: 10px;
  cursor: pointer;
  z-index: 3;
  border-radius: 50%;
  outline: none;
  transition: background-color 0.3s ease;
}

.custom-carousel__control--prev {
  left: 20px;
}

.custom-carousel__control--next {
  right: 20px;
}

.custom-carousel__control:hover {
  background-color: rgba(0, 0, 0, 0.5);
}


/* Fade-in Animation for the Carousel */
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Logo Fade-in Animation */
@keyframes logoFadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@media (max-width:768px){
  .custom-carousel__item img {
 
    height: 30vh;
 
  }
}