/* Footer.css */
html, body {
  height: 100%;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.footer {
  background-color: #222;
  color: #fff;
  padding: 40px 20px;
  position: relative;
  z-index: 1;
  overflow: hidden;
}

.footer-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
  gap: 20px;
  position: relative;
}

.footer-section h3 {
  color: #ffcc00;
  margin-bottom: 15px;
}

.footer-section p, .footer-section ul {
  margin: 0;
  line-height: 1.6;
  position: relative;
}

.footer-section ul {
  list-style: none;
  padding: 0;
}

.footer-section ul li {
  margin-bottom: 10px;
  cursor: pointer;
  transition: color 0.3s ease, transform 0.3s ease;
}

.footer-section ul li:hover {
  color: #ffcc00;
  transform: translateX(5px);
}

.footer-section a {
  color: #fff;
  text-decoration: none;
  transition: color 0.3s ease;
}

.footer-section a:hover {
  color: #ffcc00;
}

.footer-section .icon {
  margin-right: 8px;
  color: #ffcc00;
  transition: transform 0.3s ease;
}

.footer-section p:hover .icon {
  transform: rotate(360deg);
}

.social-icons a {
  font-size: 1.5rem;
  margin-right: 10px;
  color: #fff;
  transition: color 0.3s ease, transform 0.3s ease;
}

.social-icons a:hover {
  color: #ffcc00;
  transform: scale(1.2);
}

.footer-bottom {
  text-align: center;
  border-top: 1px solid #444;
  padding-top: 20px;
  margin-top: 20px;
}

.footer-bottom p {
  margin: 5px 0;
}

.footer-bottom a {
  color: #ffcc00;
  text-decoration: none;
}

.footer-bottom a:hover {
  text-decoration: underline;
}

/* Background Animation */
.footer::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(45deg, rgba(255, 214, 49, 0.872), rgba(34, 34, 34, 0.5));
  opacity: 0.2;
  z-index: -1;
  animation: gradientMove 5s infinite alternate;
}

@keyframes gradientMove {
  0% {
    transform: translateX(-50%) translateY(-50%);
  }
  100% {
    transform: translateX(50%) translateY(50%);
  }
}

/* Responsive Styles */
@media (max-width: 768px) {
  .footer-container {
    grid-template-columns: 1fr;
    text-align: center;
  }

  .footer-section {
    margin-bottom: 30px;
  }

  .social-icons a {
    margin-right: 5px;
  }
}
