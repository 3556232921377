/* Scoped Loading Container */
.loading-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-color: #000000; /* Black background */
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 9999;
}

/* Scoped Logo */
.loading-container .loading-logo {
    width: 150px;
    height: auto;
    margin-bottom: 20px;
    animation: loading-fade-in 2s ease-in-out;
}

/* Interactive Company Name */
.loading-container .loading-company-name {
    font-size: 24px;
    color: #ffffff; /* White text color */
    margin-bottom: 20px;
    animation: loading-name-fade-in 2s ease-in-out,
               loading-name-color-change 4s infinite alternate;
}

/* Loading Bar */
.loading-container .loading-bar {
    width: 60%;
    max-width: 400px;
    height: 10px;
    background-color: #333333; /* Dark grey bar background */
    border-radius: 5px;
    overflow: hidden;
    margin-top: 20px;
}

/* Loading Progress */
.loading-container .loading-progress {
    height: 100%;
    background-color: #3498db; /* Blue progress color */
    border-radius: 5px;
    transition: width 0.3s ease-in-out;
}

/* Loading Percentage */
.loading-container .loading-percentage {
    margin-top: 10px;
    color: #ffffff;
    font-size: 16px;
}

/* Animations */
@keyframes loading-name-fade-in {
    0% { opacity: 0; }
    100% { opacity: 1; }
}

@keyframes loading-name-color-change {
    0% { color: #ffffff; } /* Start with white */
    100% { color: #3498db; } /* Change to blue */
}
