/* AdminDashboard.css */
.admin-dashboard {
  font-family: Arial, sans-serif;
  padding: 20px;
  background-color: #f0f0f0;
  margin-top: 70px;
  
  min-height: 100vh;
 
}

.dashboard-wrapper {
  display: flex;
  width: 100%;
}


.dashboard-content {
 
  margin-left: 0px; /* Adjusted to match the sidebar width */
  padding: 20px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  flex-grow: 1; /* Ensures the content takes up the remaining space */
  max-width: calc(100% - 250px); /* Ensures content does not overflow */
  margin-top: 20px; /* Adjust the margin as needed */
}

.dashboard-buttons {
  display: flex;
  
  justify-content: center;
  margin: 20px 0;
}

.back{
  height: 85vh;
  background-image: url('../images/caro4.avif');
  background-size: cover;
  background-position: center;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  text-align: center;
  position: relative;
  overflow: hidden;
}
.back::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Adjust the opacity here (0.5) */
  z-index: 1;
}

.back > * {
  position: relative;
  z-index: 2;
}

.back h1 {
  font-size: 3rem;
  margin-bottom: 1rem;
  animation: fadeInDown 1s ease-out;
}

.dashboard-button {
  padding: 15px 25px;
  margin: 0 10px;
  font-size: 16px;
  color: #fff;
  background-color: #0288d1;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.3s;
}

.dashboard-button:hover {
  background-color: #0277bd;
  transform: scale(1.05);
}

.logout-button {
  padding: 10px 20px;
  margin-bottom: 20px;
  font-size: 14px;
  color: #fff;
  background-color: #ff6b6b;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.3s;
}

.logout-button:hover {
  background-color: #ff4c4c;
  transform: scale(1.05);
}

