/* Sidebar.css */
.sidebar {
   margin-left: -10px;
    width: 250px;
    height: 100vh; /* Set height to 100% to make it scrollable with the page */
    background-color: #003366;
    color: #fff;
    padding: 20px;
    box-shadow: 2px 0 5px rgba(0, 0, 0, 0.2);
    position: relative; /* Changed to relative so it scrolls with the page */
}

.sidebar h2 {
    margin: 0 0 20px;
}

.sidebar ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.sidebar ul li {
    padding: 10px;
    cursor: pointer;
    border-radius: 4px;
    transition: background-color 0.3s ease;
}

.sidebar ul li.active,
.sidebar ul li:hover {
    background-color: #555;
}
