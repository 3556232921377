.project-form {
    max-width: 600px;
    margin: 50px auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 8px;
    background-color: #f9f9f9;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.project-form h1 {
    text-align: center;
    margin-bottom: 20px;
    font-size: 24px;
    color: #333;
}

.project-form form {
    display: flex;
    flex-direction: column;
}

.project-form label {
    margin-bottom: 8px;
    font-weight: bold;
    color: #555;
}

.project-form input[type="text"],
.project-form textarea {
    padding: 10px;
    margin-bottom: 15px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
    width: 100%;
    box-sizing: border-box;
}

.project-form input[type="file"] {
    margin-bottom: 15px;
}

.project-form textarea {
    height: 100px;
    resize: vertical;
}

.project-form button {
    padding: 12px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.project-form button:hover {
    background-color: #0056b3;
}

@media (max-width: 768px) {
    .project-form {
        padding: 15px;
    }

    .project-form h1 {
        font-size: 20px;
    }

    .project-form button {
        padding: 10px 16px;
    }
}
